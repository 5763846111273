<template>
  <div class="min-h-screen bg-blue-300">
    <TnHeader />

    <img
      src="@/assets/cloud-left.svg"
      alt="cloud"
      class="absolute top-[250px] hidden h-[60px] md:block"
    />
    <img
      src="@/assets/cloud-right.svg"
      alt="cloud"
      class="absolute right-0 top-[500px] hidden h-[60px] md:block"
    />

    <div class="relative z-10">
      <slot />
    </div>
    <TnFooter />
  </div>
</template>
